/* todo: delete file if not needed */
.round:focus
{
border-color:2px solid red !important; 
box-shadow: 0 0 0 0.2rem red !important;
}


.round:focus-visible
{
    border-color:2px solid red !important; 
    box-shadow: 0 0 0 0.2rem red !important;
}
.quota:focus
{
border-color:2px solid red !important; 
box-shadow: 0 0 0 0.2rem red !important;
}


.quota:focus-visible
{
    border-color:2px solid red !important; 
    box-shadow: 0 0 0 0.2rem red !important;
}
.category:focus
{
border-color:2px solid red !important; 
box-shadow: 0 0 0 0.2rem red !important;
}


.category:focus-visible
{
    border-color:2px solid red !important; 
    box-shadow: 0 0 0 0.2rem red !important;
}
.rank:focus
{
border-color:2px solid red !important; 
box-shadow: 0 0 0 0.2rem red !important;
}


.rank:focus-visible
{
    border-color:2px solid red !important; 
    box-shadow: 0 0 0 0.2rem red !important;
}