#menu_container {
    box-shadow: 0px 2px 10px 2px lightgray;
    height: auto;
    padding:6px 4px 4px;
    position: fixed !important;
    top:0px;left:0px;right: 0px;
    z-index:5;
    background: white;
}
.header_lgoo{
    height: 44px;
}
.messages_display{
    position:fixed;
    top:20px;
    right:20px;
    max-width:300px;
    z-index:5;
}
.message_error_container {
    position: fixed;
    bottom: 4vh;
    right: 10px;
    left: 10px;
    padding: 4px;
    z-index: 2;
    text-align: center;
}
.message_error_container span {
    color: #ffff;
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    border: 1px dotted white;
}
.message_text {
    background-color: #44A1A0;
}
.error_text {
    background-color: #B23A48;
}
.small_text{
    font-size:12px;
    font-weight: 400;
}
label.small_text{
    margin-bottom:3px;
}
.edit_container .form-group{
    margin-bottom:8px !important;
}
.edit_container .card, .add_container .card, .bulk_container .card{
    box-shadow: none;
    border:1px solid lightgrey;
}
.form-control {
    color:#233f78 !important;
    font-size: 15px !important;
}
.icon_button{
    border:none;
    background:none;
    opacity:0.8;
    font-size: 16px;
    margin:0px;
}
.icon_button:hover{
    opacity:1;
    font-weight:bolder;
}
.table{
    margin-top: 20px;
    text-align:center;
}
.table th{
    background-color:#233f78;
    color: #ffffff;
    font-weight: 800;
    font-size:14px;
    text-align:center;
    vertical-align: middle !important;
}
.table td{
    color:#233f78;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    vertical-align: middle !important;
}
.form-group img{
    height:80px !important;
    max-width: 100% !important;
    margin:2px auto !important;
}
.search_btn{
    border:none;
    padding:auto 30px !important;
}
.breadcrumb{
    background : transparent !important;
}
.breadcrumb-item a{
    color:#233f78 !important;
}
.breadcrumb > li + li:before {
    content: '\3E' !important;
}
.breadcrumb-item a:hover, .breadcrumb-item{
    font-size:12px !important;
    color: #f37421 !important;
}
h4.page_header{
    text-transform: uppercase;
    margin-top: 10px;
}
.table{
    margin-top: 0px;
}
.add_container, .edit_container, .bulk_container{
    margin-bottom:15px;
}
ul.pagination{
    float:right;
}
ul.pagination li{
    padding:8px 13px;
    background:#ffff;
    font-weight: 500;
    border:1px solid rgba(0,0,0,0.2);
}
ul.pagination a{
    color:#233f78;
}
ul.pagination li.active{
    background:#233f78;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.6);
}
ul.pagination li.active a{
    color:#ffff !important;
}
ul.pagination li.disabled{
    opacity:0.5;
}

/* Stylesheet for movile devices */
@media (max-width:767px)
{
    ul.pagination{
        float: none;
        justify-content: center !important;
    }
}